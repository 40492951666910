$(function(){

    var App = {
        offerParams: null
    };

    //$('select').niceSelect();

    $('#couponIframe').iFrameResize();

    /**
     * Reset all modals on close
     */
    $('.modal.resetable').on('hidden.bs.modal', function() {
        $('.modal-body, .modal-title', this).html('');
    });

    var $masonry = $('.masonry').imagesLoaded( function() {
        /*
        $('.masonry').isotope({
            // options
            itemSelector: '.masonry-grid-item',
            getSortData: {
                sort: '[data-sort]'
            },
            // sort by color then number
            sortBy: ['sort']
            //layoutMode: 'fitRows'
        });
        */
        // init Masonry after all images have loaded
        $masonry.masonry({
            itemSelector: '.masonry-grid-item' // use a separate class for itemSelector, other than .col-
            //columnWidth: '.masonry-grid-sizer',
            //percentPosition: true
        });
    });
    
    $('.arrow > a').on('click', function(e) {
        var header = $('.site-header'),
            content = header.next();
        $('html, body').animate({
            scrollTop: content.offset().top
        }, 250), e.preventDefault();
    });

    $('.search-offer-form select').change(function(e){
        //buildOfferUrl();
    });

    $('.county.load-municipalities').change(function(e) {

        disable('.municipality');

        var countySlug = $(this).val();
        var url = '/counties/' + countySlug + '/municipalities';
        var options = '<option value="">Välj kommun</option>\n';

        if (countySlug === '') {
            $('.municipality').html(options);
            return;
        }

        showLoader('.county-loader');

        $.get(url, function(response) {
            if (response.length) {
                $.each(response, function(index){
                    options += '<option value="'+response[index].slug+'">'+response[index].name+'</option>\n';
                });
            }

            $('.municipality').html(options);
            enable('.municipality');
            hideLoader('.county-loader');
        });
    });

    $('.search-offer-form .submit').on('click', function(e) {
        e.preventDefault();

        if (! isOffersSearchFormValid()) return;

        url = buildOfferUrl();
        window.location = url;
    });

    $('.input-error > a.error-close').on('click', function(){
        $(this).parent().fadeOut(250);
    });

    function buildOfferUrl() {

        var url = '/erbjudanden';

        $('.search-offer-form select').each(function(index){
            if ($(this).val().length > 0) {
                url += '/' + $(this).val();
            }
        });

        return url;
    }

    function isOffersSearchFormValid() {

        var errors = [];

        $('.search-offer-form .form-group.required').removeClass('error');

        $('.search-offer-form .form-group.required select').filter(function(){
            if (! this.value) {
                errors.push(1);
            }

            return ! this.value;
        }).parent().addClass('error');

        return errors.length ? false : true;
    }

    function disable(el) {
        $(el).prop('disabled', true);
    }

    function enable(el) {
        $(el).prop('disabled', false);
    }

    function showLoader(el) {
        $(el).addClass('show');
    }

    function hideLoader(el) {
        $(el).removeClass('show');
    }
});
